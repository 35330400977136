import * as React from "react"
import { Link } from "gatsby"

import Footer from "../components/footer"

// styles
const pageStyles = {
  padding: 96,
}
const headingStyles = {
  fontFamily: "Montserrat, sans-serif",
  fontSize: 48,
  color: "#DDFF38",
  display: "block",
  marginBottom: 64,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>Not found | lorenzobruno.com</title>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry the page you were looking for does not exist.
        <br /><br />
        <Link to="/">← Go back</Link>
        {process.env.NODE_ENV === "development" ? (
          <>
            <br /><br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Footer />
      </p>
    </main>
  )
}

export default NotFoundPage
